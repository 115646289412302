import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import thunkMiddlewear from 'redux-thunk';

const store = createStore(rootReducer, applyMiddleware(thunkMiddlewear));

export const setupStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddlewear));
};

export default store;
