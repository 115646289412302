import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deLang from './languages/de.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    lng: 'de',
    resources: deLang,
    // detection: {
    //   // Order and from where user language should be detected:
    //   order: ['navigator'],
    // },
    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
