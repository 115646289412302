import axios from 'axios';
import { deleteTrailingSlash } from '../common/deleteTrailingSlash';

const refdataUrl = process.env.REACT_APP_REFDATA_URL;

export const configApi = {
  getConfigJson(sid) {
    return axios
      .get(`/site/${sid}/config.json`)
      .then((response) => response)
      .catch((err) => err);
  },
  getQuestionnaireJson(path) {
    return axios
      .get(path)
      .then((response) => response)
      .catch((err) => err);
  },
  getInsuranceJson(path) {
    return axios
      .get(`${refdataUrl ? deleteTrailingSlash(refdataUrl) : ''}/insurance-organizations.json`)
      .then((response) => response)
      .catch((err) => err);
  },
};
