import { toast } from 'react-toastify';
import { configApi } from '../../api/configApi';
import { setConsentText, setQuestionsList } from './patientInfoActions';

export const SET_LOGO = 'SET_LOGO';
export const SET_MAIN_COLOR = 'SET_MAIN_COLOR';
export const SET_CONFIG_ID = 'SET_CONFIG_ID';
export const SET_PUB_KEY = 'SET_PUB_KEY';
export const SET_PRACTICE_NAME = 'SET_PRACTICE_NAME';
export const SET_CONFIG_ERROR = 'SET_CONFIG_ERROR';
export const SET_CHECKBOX_SIGN_CHECK = 'SET_CHECKBOX_SIGN_CHECK';
export const SET_INVOICE_TO_OPTIONS = 'SET_INVOICE_TO_OPTIONS';
export const SET_INSURANCES_LIST = 'SET_INSURANCES_LIST';
export const SET_PEDIATRICIAN = 'SET_PEDIATRICIAN';

export const setPracticeName = (payload) => ({ type: SET_PRACTICE_NAME, payload });
export const setMainLogo = (payload) => ({ type: SET_LOGO, payload });
export const setMainColor = (payload) => ({ type: SET_MAIN_COLOR, payload });
export const setConfigId = (payload) => ({ type: SET_CONFIG_ID, payload });
export const setPubkey = (payload) => ({ type: SET_PUB_KEY, payload });
export const setConfigError = (payload) => ({ type: SET_CONFIG_ERROR, payload });
export const setCheckBoxAndSignCheck = (payload) => ({ type: SET_CHECKBOX_SIGN_CHECK, payload });
export const setInvoiceToOptions = (payload) => ({ type: SET_INVOICE_TO_OPTIONS, payload });
export const setInsurancesList = (payload) => ({ type: SET_INSURANCES_LIST, payload });
export const setPediatrician = (payload) => ({ type: SET_PEDIATRICIAN, payload });

export const getConfig = (uuid) => async (dispatch) => {
  try {
    const response = await configApi.getConfigJson(uuid);
    if (response.status === 200) {
      const { id, name, pubkey, img, color } = response.data.site;

      if (response.data.registration?.questionnaire) {
        dispatch(getQuestionsList(response.data.registration.questionnaire));
      }

      document.documentElement.style.setProperty('--baseColor', color);

      dispatch(setConfigId(id));
      dispatch(setPracticeName(name));
      dispatch(setPubkey(pubkey));
      dispatch(setMainLogo(img));
      dispatch(setMainColor(color));

      if (response.data.registration?.scope_invoice_to) {
        dispatch(setInvoiceToOptions(response.data.registration.scope_invoice_to));
      }
      if (response.data.registration?.pediatrician)
        dispatch(setPediatrician(response.data.registration.pediatrician));
      return id;
    } else throw new Error('Get config error');
  } catch (err) {
    console.log(err);
  }
};

export const getQuestionsList = (path) => async (dispatch) => {
  try {
    const response = await configApi.getQuestionnaireJson(path);
    if (response.status === 200) {
      const { questionList, consentText } = response.data.item.reduce(
        (prevState, item) => {
          if (!!item.item?.some((subItem) => subItem.linkId === 'i_have_read_and_agree')) {
            prevState.consentText = item;
          } else {
            prevState.questionList.push(item);
          }
          return prevState;
        },
        { questionList: [], consentText: null },
      );

      dispatch(setQuestionsList(questionList));

      let consentTextValid;
      if (consentText) {
        const agreeCheck = consentText.item.some(
          (i) => i.type === 'boolean' && i.linkId === 'i_have_read_and_agree',
        );
        const attachmentCheck = consentText.item.some(
          (i) => i.type === 'attachment' && i.linkId.includes('manual_signature'),
        );
        dispatch(setCheckBoxAndSignCheck({ checkBox: agreeCheck, sign: attachmentCheck }));
        if (consentText.required === true) {
          consentTextValid = consentText.type === 'group' && agreeCheck && attachmentCheck;
        } else if (consentText.required === false) consentTextValid = true;
      }
      if (!consentTextValid && consentText) {
        dispatch(setConfigError(true));
        console.error('Invalid consent text object');
        toast.error('Error! Invalid config file. Please check.');
      } else dispatch(setConsentText(consentText));
    } else throw new Error('Get config error');
  } catch (err) {
    console.log(err);
  }
};

export const getInsurancesJson = () => async (dispatch) => {
  try {
    const response = await configApi.getInsuranceJson();
    if (response.status === 200) {
      dispatch(setInsurancesList(response.data));
    } else throw new Error('Get config error');
  } catch (err) {
    toast.error('Error receiving insurances list!');
    console.log(err);
  }
};
