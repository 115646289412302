import axios from 'axios';
import { deleteTrailingSlash } from '../common/deleteTrailingSlash';

const apiUrl = process.env.REACT_APP_PEA_API_URL;

export const patientCardApi = {
  uploadToPea(idClient, idPatient, data) {
    return axios
      .put(
        `${deleteTrailingSlash(apiUrl)}/site/${idClient.toLowerCase()}/reg/${idPatient.toLowerCase()}.jwe`,
        data,
        {
          headers: {
            'Content-Type': 'text/plain',
          },
        },
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return (error.response && error.response.data) || 'Something went wrong';
      });
  },
};
