import { combineReducers } from 'redux';
import patientInfo from './patientInfo';
import settings from './adminSettings';

let rootReducer = combineReducers({
  patientInfo,
  settings,
});

export default rootReducer;
