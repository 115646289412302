import React from 'react';
import './styles/style.scss';
import './App.scss';
import { Switch, Route, useHistory } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Suspense, useEffect } from 'react';
import LoadingPage from './components/LoadingPage';
import { getConfig } from './redux/actions/adminSettings';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from './hooks/useQueryParam';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const NotFoundPage = React.lazy(() => import('./pages/NotFound'));

function App() {
  const dispatch = useDispatch();
  const { configId } = useSelector((state) => state.settings);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const sidParam = query.get('sid');
    if (sidParam && !configId) {
      dispatch(getConfig(sidParam)).then((resp) => {
        if (!resp) history.push(`/notFound`);
      });
    } else if (!sidParam) history.push(`/notFound`);
  }, [configId, dispatch, history, query]);

  return (
    <div className="app-wrapper">
      <div className={'app'}>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route path="/notFound" component={NotFoundPage} />
            <Route path="/" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </Suspense>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        draggable
        closeButton={false}
      />
    </div>
  );
}

export default App;
