import React from 'react';
import LoadSpinner from '../LoadSpinner';

import classes from "./loadingPage.module.scss";

const LoadingPage = () => {
  return (
    <div className={classes.loadPage}>
      <LoadSpinner loading={true} />
    </div>
  )
}

export default LoadingPage;
