import moment from 'moment';
import { patientCardApi } from '../../api/axiosPatientApi';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import * as jose from 'jose';

export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const SAVE_CONFIRM_FORM = 'SAVE_CONFIRM_FORM';
export const SET_CARD_SCANNER_DATA = 'SET_CARD_SCANNER_DATA';
export const SET_QUESTIONS_LIST = 'SET_QUESTIONS_LIST';
export const SET_PDF = 'SET_PDF';
export const SET_JSONS = 'SET_JSONS';
export const SET_CONSENT_TEXT = 'SET_CONSENT_TEXT';
export const RESET_ALL_VALUES = 'RESET_ALL_VALUES';
export const SET_JSON_SENT = 'SET_JSON_SENT';

export const saveFormData = (payload) => ({ type: SAVE_FORM_DATA, payload });
export const saveConfirmForm = (payload) => ({ type: SAVE_CONFIRM_FORM, payload });
export const setDataFromCardScanner = (payload) => ({ type: SET_CARD_SCANNER_DATA, payload });
export const setQuestionsList = (payload) => ({ type: SET_QUESTIONS_LIST, payload });
export const setPdf = (payload) => ({ type: SET_PDF, payload });
export const setJsons = (payload) => ({ type: SET_JSONS, payload });
export const setConsentText = (payload) => ({ type: SET_CONSENT_TEXT, payload });
export const resetAllValues = () => ({ type: RESET_ALL_VALUES });
export const setJsonSent = (payload) => ({ type: SET_JSON_SENT, payload });

export const submitAllForm = (blobPdf) => async (dispatch, getState) => {
  try {
    const state = getState();

    const { patientInfo, invoice, contacts, representative } = state.patientInfo.savedFormData;
    const { configId, pediatrician } = state.settings;

    const patientId = uuidv4().toLowerCase();

    const patientName = {
      text: patientInfo.firstName + ' ' + patientInfo.lastName,
      given: [patientInfo.firstName],
      family: patientInfo.lastName,
    };
    if (patientInfo.title) patientName.prefix = [patientInfo.title];

    const dateOfBirth =
      patientInfo.dateOfBirth && moment(new Date(patientInfo.dateOfBirth)).format('YYYY-MM-DD');

    const adressOfPacient = {
      line: [contacts.street],
      city: contacts.village,
      postalCode: contacts.zipCode,
      country: contacts.country.value,
    };

    const mobilPhoneInvoice = {
      system: 'phone',
      value: invoice.mobilePhone,
      use: 'mobile',
    };
    const homePhoneInvoice = {
      system: 'phone',
      value: invoice.telephone,
      use: 'home',
    };
    const emailPhoneInvoice = {
      system: 'email',
      value: invoice.email,
      use: 'home',
    };
    const mobilPhoneContacts = {
      system: 'phone',
      value: contacts.mobilePhone,
      use: 'mobile',
      // rank: 1,
    };
    const homePhoneContacts = {
      system: 'phone',
      value: contacts.telephone,
      use: 'home',
    };
    const emailPhoneContacts = {
      system: 'email',
      value: contacts.email,
      use: 'home',
    };

    const mobilPhoneRepresentative = {
      system: 'phone',
      value: representative.mobilePhone,
      use: 'mobile',
    };
    const homePhoneRepresentative = {
      system: 'phone',
      value: representative.telephone,
      use: 'home',
    };
    const emailPhoneRepresentative = {
      system: 'email',
      value: representative.email,
      use: 'home',
    };

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const photoFile = state.patientInfo.savedFormData.photo;

    const photo = {
      data: '',
      contentType: '',
    };

    if (photoFile) {
      const base64File = await toBase64(photoFile);

      const photoString = base64File && base64File.split(';base64,');
      const dataType = photoString && photoString[0].split('data:');

      photo.contentType = dataType ? dataType[1] : null;
      photo.data = photoString ? photoString[1] : null;
    }

    const uuid = configId.toLowerCase();

    const patientResource = {
      resourceType: 'Patient',
      id: patientId,
      name: [patientName],
      birthDate: dateOfBirth,
      gender: patientInfo.gender && patientInfo.gender.value,
      address: [adressOfPacient],
    };

    if (contacts.AHV) patientResource.identifier = [{ system: 'urn:oid:2.16.756.5.32', value: contacts.AHV }];

    if (photo['data']) {
      patientResource['photo'] = [photo];
    }

    if (!pediatrician) {
      if (contacts.telephone)
        patientResource.telecom = patientResource.telecom
          ? [...patientResource.telecom, homePhoneContacts]
          : [homePhoneContacts];

      if (contacts.mobilePhone)
        patientResource.telecom = patientResource.telecom
          ? [...patientResource.telecom, mobilPhoneContacts]
          : [mobilPhoneContacts];

      if (contacts.email)
        patientResource.telecom = patientResource.telecom
          ? [...patientResource.telecom, emailPhoneContacts]
          : [emailPhoneContacts];
    } else {
      if (representative.telephone)
        patientResource.telecom = patientResource.telecom
          ? [...patientResource.telecom, homePhoneRepresentative]
          : [homePhoneRepresentative];

      if (representative.mobilePhone)
        patientResource.telecom = patientResource.telecom
          ? [...patientResource.telecom, mobilPhoneRepresentative]
          : [mobilPhoneRepresentative];

      if (representative.email)
        patientResource.telecom = patientResource.telecom
          ? [...patientResource.telecom, emailPhoneRepresentative]
          : [emailPhoneRepresentative];
    }

    //template for result JSON
    const resultJsonObj = {
      version: 1,
      id: patientId,
      sid: uuid,
      creationTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS UTC'),
      patient: patientResource,
      attachments: [],
    };

    if (patientInfo.invoiceTo === 'different') {
      if (patientInfo.invoiceToType === 'person') {
        const personName = {
          text: invoice.firstName + ' ' + invoice.lastName,
          given: [invoice.firstName],
          family: invoice.lastName,
        };
        if (invoice.title) personName.prefix = [invoice.title];

        const dateOfBirth = invoice.dateOfBirth && moment(new Date(invoice.dateOfBirth)).format('YYYY-MM-DD');

        const relatedPerson = {
          resourceType: 'RelatedPerson',
          id: 'PAYOR',
          patient: {
            reference: 'Patient/' + patientId,
          },
          relationship: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
                  code: 'PAYOR',
                },
              ],
            },
          ],
          name: [personName],
          birthDate: dateOfBirth,
          gender: invoice.gender && invoice.gender.value,
          address: [
            {
              line: [invoice.street],
              city: invoice.village,
              postalCode: invoice.zipCode,
              country: invoice.country.value,
            },
          ],
          telecom: [emailPhoneInvoice],
        };

        if (invoice.AHV) relatedPerson.identifier = [{ system: 'urn:oid:2.16.756.5.32', value: invoice.AHV }];

        if (invoice.telephone) relatedPerson.telecom.push(homePhoneInvoice);
        if (invoice.mobilePhone) relatedPerson.telecom.push(mobilPhoneInvoice);

        if (!resultJsonObj.relatedPersons) resultJsonObj.relatedPersons = [];
        resultJsonObj.relatedPersons.push(relatedPerson);
      }
      if (patientInfo.invoiceToType === 'organisation') {
        const organisation = {
          resourceType: 'Organization',
          type: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/organization-type',
                  code: 'PAY',
                },
              ],
            },
          ],

          name: invoice.organisation,
          address: [
            {
              line: [invoice.street],
              city: invoice.village,
              postalCode: invoice.zipCode,
              country: invoice.country.value,
            },
          ],
          telecom: [emailPhoneInvoice],
        };

        if (invoice.telephone) organisation.telecom.push(homePhoneInvoice);
        if (invoice.mobilePhone) organisation.telecom.push(mobilPhoneInvoice);

        if (!resultJsonObj.organizations) resultJsonObj.organizations = [];
        resultJsonObj.organizations.push(organisation);
      }
    }

    if (contacts.representativeCheck) {
      if (!resultJsonObj.relatedPersons) resultJsonObj.relatedPersons = [];
      const personName = {
        text: representative.firstName + ' ' + representative.lastName,
        given: [representative.firstName],
        family: representative.lastName,
      };

      if (representative.title) personName.prefix = [representative.title];

      const dateOfBirth =
        representative.dateOfBirth && moment(new Date(representative.dateOfBirth)).format('YYYY-MM-DD');

      const representativePerson = {
        resourceType: 'RelatedPerson',
        id: 'GUARD',
        patient: {
          reference: 'Patient/' + patientId,
        },
        relationship: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
                code: 'GUARD',
              },
            ],
          },
        ],
        name: [personName],
        birthDate: dateOfBirth,
        gender: representative.gender && representative.gender.value,
        address: [
          {
            line: [representative.street], // Street name, number, direction & P.O. Box etc.
            city: representative.village, // Name of city, town etc.
            postalCode: representative.zipCode, // Postal code for area
            country: representative.country.value,
          },
        ],
      };

      if (representative.telephone)
        representativePerson.telecom = representativePerson.telecom
          ? [...representativePerson.telecom, homePhoneRepresentative]
          : [homePhoneRepresentative];

      if (representative.mobilePhone)
        representativePerson.telecom = representativePerson.telecom
          ? [...representativePerson.telecom, mobilPhoneRepresentative]
          : [mobilPhoneRepresentative];

      if (representative.email)
        representativePerson.telecom = representativePerson.telecom
          ? [...representativePerson.telecom, emailPhoneRepresentative]
          : [emailPhoneRepresentative];

      resultJsonObj.relatedPersons.push(representativePerson);
    }

    if (patientInfo.insurance) {
      const coverage = {
        resourceType: 'Coverage',
        beneficiary: { reference: 'Patient/' + patientId },
        payor: [{ reference: 'Patient/' + patientId }],
      };

      //if Versichertennummer has defined
      if (patientInfo.insuranceNumber)
        coverage.identifier = [
          {
            system: 'urn:oid:2.16.756.5.30.1.123.100.1.1.1',
            value: patientInfo.insuranceNumber,
          },
        ];

      //if not 'other' insurance
      if (patientInfo.insurance.value !== 'other' && patientInfo.insurance.value !== 'none') {
        coverage.policyHolder = {
          identifier: patientInfo.insurance.value,
        };
      }
      //if 'other' insurance
      else if (patientInfo.insurance.value === 'other') {
        coverage.policyHolder = {
          reference: 'Organization/INS',
        };

        const organisation = {
          resourceType: 'Organization',
          type: [
            {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/organization-type',
                  code: 'INS',
                },
              ],
            },
          ],
          name: patientInfo.insuranceCustom,
          address: [
            {
              line: [patientInfo.insuranceStreet],
              city: patientInfo.insuranceVillage,
              postalCode: patientInfo.insuranceZipCode,
              country: patientInfo.insuranceCountry.value,
            },
          ],
        };

        if (!resultJsonObj.organizations) resultJsonObj.organizations = [];
        resultJsonObj.organizations.push(organisation);
      } else if (patientInfo.insurance.value === 'none') {
        delete coverage.policyHolder;
      }

      if (patientInfo.invoiceTo === 'insurance') {
        coverage.payor = [coverage.policyHolder];
      } else if (patientInfo.invoiceTo === 'different') {
        if (patientInfo.invoiceToType === 'person') coverage.payor = [{ reference: 'RelatedPerson/PAYOR' }];
        if (patientInfo.invoiceToType === 'organisation')
          coverage.payor = [{ reference: 'Organization/PAY' }];
      } else if (patientInfo.invoiceTo === 'legalRepresentative') {
        coverage.payor = [
          {
            reference: 'RelatedPerson/GUARD',
          },
        ];
      }

      resultJsonObj.coverage = coverage;
    }

    const questionsAnswers = state.patientInfo?.questionsList?.map((item, index) => {
      const preparedAnswer =
        (state.patientInfo.questions && state.patientInfo.questions[item.linkId]) || null;
      const answer = item.repeats !== true ? preparedAnswer || '-' : preparedAnswer?.join('; ') || '-';

      const answers = {
        linkId: item.linkId,
        text: item.text,
      };
      if (item.type === 'text')
        answers.answer = [
          {
            valueString: answer,
          },
        ];
      else if (item.repeats && item.type === 'choice') {
        if (preparedAnswer && preparedAnswer.length > 0)
          answers.answer = preparedAnswer.map((option) => ({
            valueCoding: {
              code: option.replace(/ /g, '_'),
              display: option,
            },
          }));
      } else if (!item.repeats && item.type === 'choice') {
        if (preparedAnswer)
          answers.answer = [
            {
              valueCoding: {
                code: preparedAnswer.replace(/ /g, '_'),
                display: preparedAnswer,
              },
            },
          ];
      }

      return { answers };
    });

    if (questionsAnswers) {
      const questionnaire = {
        resourceType: 'Questionnaire',
        status: 'active',
        item: [...state.patientInfo.questionsList],
        id: uuid,
      };

      const sign = state.patientInfo.sign;
      const consentQuestion = state.patientInfo.consentText;
      if (consentQuestion) questionnaire.item.push(consentQuestion);

      const questionnaireResponse = {
        id: uuid,
        resourceType: 'QuestionnaireResponse',
        subject: {
          reference: 'Patient/' + patientId,
        },
        status: 'completed',
        item: questionsAnswers.map((item) => item.answers),
      };

      resultJsonObj.questionnaire = questionnaire;
      resultJsonObj.questionnaireResponse = questionnaireResponse;

      if (consentQuestion) {
        const checkBoxAgree = consentQuestion.item?.find(
          (i) => i.type === 'boolean' && i.linkId === 'i_have_read_and_agree',
        );
        const attachmentCheck = consentQuestion.item.find(
          (i) => i.type === 'attachment' && i.linkId.includes('manual_signature'),
        );

        const consentTextAnswer = {
          linkId: consentQuestion.linkId,
          text: consentQuestion.text,
          item: [
            {
              linkId: checkBoxAgree?.linkId,
              text: checkBoxAgree?.text,
              answer: [
                {
                  valueBoolean: state.patientInfo.isAgree,
                },
              ],
            },
          ],
        };

        if (sign) {
          const signAttachment = {
            contentType: '',
            data: '',
          };
          const photoString = sign && sign.split(';base64,');
          const dataType = photoString && photoString[0].split('data:');

          signAttachment.contentType = dataType ? dataType[1] : null;
          signAttachment.data = photoString ? photoString[1] : null;

          consentTextAnswer.item.push({
            linkId: attachmentCheck.linkId,
            answer: [
              {
                valueAttachment: signAttachment,
              },
            ],
          });
        }

        resultJsonObj.questionnaireResponse.item.push(consentTextAnswer);
      }
    }

    if (blobPdf) {
      const base64File = await toBase64(blobPdf);
      const pdfString = base64File && base64File.split(';base64,');
      const dataType = pdfString && pdfString[0].split('data:');
      resultJsonObj.attachments.push({
        title: 'PeaRegistration',
        contentType: dataType ? dataType[1] : null,
        data: pdfString ? pdfString[1] : null,
      });
    }

    ///encryption
    const configForJWK = state.settings.pubKey.jwk;

    const ecPublicKey = await jose.importJWK(configForJWK, state.settings.pubKey.alg);

    const json = JSON.stringify(resultJsonObj);

    const jwe = await new jose.CompactEncrypt(new TextEncoder().encode(json))
      .setProtectedHeader({ alg: state.settings.pubKey.alg, enc: state.settings.pubKey.enc })
      .encrypt(ecPublicKey);

    dispatch(setJsons({ unencrypted: json, encrypted: jwe }));

    ////For test decryption (for tests)
    // const ecPrivateKey = {
    //   kty: 'EC',
    //   d: '0kQ7frd87gy2LIlFMHoJQfMQtkHdk4rmf1BwdRdXMng',
    //   use: 'enc',
    //   crv: 'P-256',
    //   kid: '3f7fe68b-b34e-4a33-8b87-607fe654a124',
    //   x: '_BtooNNWaypnf1sy-F_zihbUhxT1sd_eXU0yq5OThuI',
    //   y: 'fYz2ze4krSJMVEtzumVRoZoJZXDKwlHE5hkVqvln-nA',
    //   iat: 1674219937,
    // };
    // const ecPublicKeyEncryp = await jose.importJWK(ecPrivateKey, alg);
    // const { plaintext, protectedHeader } = await jose.compactDecrypt(jwe, ecPublicKeyEncryp);
    // console.log('------decoded---------', new TextDecoder().decode(plaintext));
  } catch (err) {
    console.log(err);
    toast.error('Error! Please try again later.');
    return err;
  }
};

export const uploadJweToPea = (sid, patientId, data) => async (dispatch) => {
  try {
    let response = await patientCardApi.uploadToPea(sid, patientId, data);
    if (response.status === 201) {
      toast.success('Übermittlung erfolgreich');
    } else throw new Error(response.error || 'Something wrong');
  } catch (err) {
    console.log(err);
    toast.error('Error! Please try again later.');
  }
};
