import {
  SET_LOGO,
  SET_MAIN_COLOR,
  SET_CONFIG_ID,
  SET_PUB_KEY,
  SET_PRACTICE_NAME,
  SET_CONFIG_ERROR,
  SET_CHECKBOX_SIGN_CHECK,
  SET_INVOICE_TO_OPTIONS,
  SET_INSURANCES_LIST,
  SET_PEDIATRICIAN,
} from '../actions/adminSettings';

let initialState = {
  logo: null,
  color: null,
  configId: null,
  pubKey: null,
  practiceName: null,
  configError: false,
  checkBoxAndSignCheck: { checkBox: false, sign: false },
  invoiceToOptions: null,
  insurancesList: null,
  pediatrician: false,
};

const adminSettings = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRACTICE_NAME:
      return {
        ...state,
        practiceName: action.payload,
      };
    case SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case SET_CONFIG_ID:
      return {
        ...state,
        configId: action.payload,
      };
    case SET_PUB_KEY:
      return {
        ...state,
        pubKey: action.payload,
      };
    case SET_MAIN_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case SET_CONFIG_ERROR:
      return {
        ...state,
        configError: action.payload,
      };
    case SET_CHECKBOX_SIGN_CHECK:
      return {
        ...state,
        checkBoxAndSignCheck: action.payload,
      };
    case SET_INVOICE_TO_OPTIONS:
      return {
        ...state,
        invoiceToOptions: action.payload,
      };
    case SET_INSURANCES_LIST:
      return {
        ...state,
        insurancesList: action.payload,
      };
    case SET_PEDIATRICIAN:
      return {
        ...state,
        pediatrician: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default adminSettings;
