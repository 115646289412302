import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import './i18next/i18n';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

document.title = ''.concat('PEA', ' - ', (process.env.REACT_APP_TITLE || '').replace('__', ' '));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
