import React from 'react';
import HashLoader from 'react-spinners/HashLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const LoadSpinner = ({ loading }) => {
  return <HashLoader color={'#0097f4'} loading={loading} css={override} size={170} />;
};

export default LoadSpinner;
