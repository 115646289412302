import {
  SAVE_FORM_DATA,
  SAVE_CONFIRM_FORM,
  SET_CARD_SCANNER_DATA,
  SET_QUESTIONS_LIST,
  SET_PDF,
  SET_JSONS,
  SET_CONSENT_TEXT,
  RESET_ALL_VALUES,
  SET_JSON_SENT,
} from '../actions/patientInfoActions';

import { countriesOptions } from '../../common/countries';

let initialState = {
  savedFormData: {
    photo: null,
    patientInfo: {
      salutation: null,
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: null,
      title: '',
      insurance: null,
      insuranceCustom: '',
      insuranceStreet: '',
      insuranceNr: '',
      insuranceZipCode: '',
      insuranceVillage: '',
      insuranceNumber: '',
      insuranceCountry: countriesOptions[0],
      // AHV: '',
      invoiceTo: '',
      invoiceToType: null,
      physicalCopyOfInvoice: false,
    },
    invoice: {
      organisation: '',
      street: '',
      nr: '',
      zipCode: '',
      village: '',
      telephone: '',
      mobilePhone: '',
      email: '',
      // maritalStatus: null,
      salutation: null,
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      country: countriesOptions[0],
      AHV: '',
      addressFromPatientCheck: false,
      gender: null,
    },
    contacts: {
      organisation: '',
      street: '',
      nr: '',
      zipCode: '',
      village: '',
      telephone: '',
      mobilePhone: '',
      email: '',
      maritalStatus: null,
      representativeCheck: false,
      representativeSecondCheck: false,
      country: countriesOptions[0],
      AHV: '',
    },
    representative: {
      salutation: null,
      title: '',
      firstName: '',
      lastName: '',
      street: '',
      zipCode: '',
      village: '',
      country: countriesOptions[0],
      gender: null,
      dateOfBirth: '',
      telephone: '',
      mobilePhone: '',
      email: '',
    },
    representativeSecond: {
      salutation: null,
      title: '',
      firstName: '',
      lastName: '',
      street: '',
      zipCode: '',
      village: '',
      country: countriesOptions[0],
      gender: null,
      dateOfBirth: '',
      telephone: '',
      mobilePhone: '',
      email: '',
    },
  },
  questions: null,
  declaration: null,
  sign: null,
  isAgree: false,
  questionsList: null,
  pdf: null,
  fhirJsons: null,
  consentText: null,
  isJsonSent: false,
};

export const formData = { ...initialState };

const patientInfo = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FORM_DATA:
      return {
        ...state,
        savedFormData: action.payload,
      };
    case SAVE_CONFIRM_FORM:
      return {
        ...state,
        questions: action.payload.questionnaire,
        sign: action.payload.sign,
        declaration: action.payload.declaration,
        isAgree: action.payload.isAgree,
      };

    case SET_CARD_SCANNER_DATA:
      const insuranceScanned = action.payload.nameOfInsutance
        ? state.insuranceList.find((item) =>
            item.resource.name.toLowerCase().includes(action.payload.nameOfInsutance.toLowerCase()),
          )
        : null;
      return {
        ...state,
        savedFormData: {
          ...state.savedFormData,
          patientInfo: {
            ...state.savedFormData.patientInfo,
            firstName: action.payload.name || '',
            lastName: action.payload.surname || '',
            insuranceNumber: action.payload.insuranceNumber || '',
            AHV: action.payload.ahvNumber || '',
            dateOfBirth: action.payload.dateOfBirth || '',
            insurance: insuranceScanned
              ? {
                  value: insuranceScanned.resource.name,
                  label: insuranceScanned.resource.name,
                  id: insuranceScanned.resource.id,
                }
              : null,
          },
        },
      };
    case SET_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.payload,
      };
    case SET_PDF:
      return {
        ...state,
        pdf: action.payload,
      };
    case SET_JSONS:
      return {
        ...state,
        fhirJsons: action.payload,
      };
    case SET_CONSENT_TEXT:
      return {
        ...state,
        consentText: action.payload,
      };
    case RESET_ALL_VALUES:
      return {
        ...state,
        savedFormData: initialState.savedFormData,
        questions: null,
        sign: null,
        isAgree: false,
        pdf: null,
        fhirJsons: null,
      };
    case SET_JSON_SENT:
      return {
        ...state,
        isJsonSent: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default patientInfo;
